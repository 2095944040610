import { analytics } from '../../../configs/firebase'
import { logEvent } from '@firebase/analytics'

const loginScreenAnalytics = {
  // Evento de visualização de tela
  trackScreenView(): void {
    if (analytics) {
      logEvent(analytics, 'screen_view_login', {
        screen_name: 'Login',
        screen_class: 'Login',
      })
    } else {
      console.error('Firebase Analytics não está inicializado.')
    }
  },

  // Registrar erro de autenticação
  trackLoginError(errorMessage: string): void {
    if (analytics) {
      logEvent(analytics, 'login_error', {
        error_message: errorMessage,
      })
    } else {
      console.error('Firebase Analytics não está inicializado.')
    }
  },

  // Evento de sucesso de login
  trackLoginSuccess(userId: string, name: string): void {
    if (analytics) {
      logEvent(analytics, 'login_success', {
        user_id: userId,
        name,
      })
    } else {
      console.error('Firebase Analytics não está inicializado.')
    }
  },
}

export default loginScreenAnalytics
