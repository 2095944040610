import React from 'react'

// Context
import { useUser } from 'context/user/user.context'

import { translate } from 'locales/index'

// Styles
import defaultImg from 'assets/images/avatar.jpg'
import * as S from './styles'

const Photo: React.FunctionComponent = () => {
  const { userData } = useUser()

  return (
    <S.PhotoProfile>
      <h3>{translate('profile.photo')}</h3>
      <div className="content-photo">
        <div className="photo-content-wrapper">
          <img src={userData.profile.photo || defaultImg} alt={userData.name} />
        </div>
      </div>
    </S.PhotoProfile>
  )
}

export default Photo
