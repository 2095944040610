import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { orderBy } from 'lodash'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import ptLocale from '@fullcalendar/core/locales/pt'
import enLocale from '@fullcalendar/core/locales/en-nz'
import { alertDanger } from 'utils/toast'

import { translate } from 'locales'

// Context
import { useScheduleConfig } from 'context/schenduleConfig/schenduleConfig.context'

// Components
import { Header, Card, Loading, Footer } from 'components'
import * as Global from 'global/styles.global'
import OverlayConfig from './OverlayConfig'

// Styles
import * as S from './styles'

import { findScheduleConfiguration, handleDateClick } from './services'
import { IDoctorSchedule } from './interfaces'

const locales = window.localStorage.getItem('@StarBem:lang')

moment.locale('pt-br', {
  weekdays: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
})

const ScheduleConfiguration = () => {
  moment.locale('pt-br')

  const calendarRef = useRef<any>(null)
  const history = useHistory()

  const { doctorSchendule, setDoctorSchendule } = useScheduleConfig()

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [loading, setLoading] = useState<boolean>(true)
  const [newSchedule, setNewSchedule] = useState<IDoctorSchedule>({})
  const [occupations, setOccupations] = useState<any[]>([])
  const [selectedOccupation, setSelectedOccupation] = useState<any>({})
  const [country, setCountry] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState<any>([])

  const findData = () => {
    const userDataStorage = window.localStorage.getItem('@StarBem:user')
    const userData: any = userDataStorage && JSON.parse(userDataStorage)

    if (userData.specialties?.length === 0) {
      history.push('/perfil')
      return alertDanger(translate('schedule.needSpecialties'))
    }

    setOccupations(
      orderBy(
        userData.specialties.filter((item: any) => item.status === 'active'),
        ['asc']
      )
    )
    setSelectedOccupation(
      orderBy(
        userData.specialties.filter((item: any) => item.status === 'active'),
        ['asc']
      )[0]
    )

    setCountry(orderBy(userData.countries.filter((pais: any) => pais)))
    setGroups(userData?.groups)

    setSelectedCountry(orderBy(userData.countries.filter((pais: any) => pais)))

    return true
  }

  const showModal = true

  useEffect(() => {
    findData()
    findScheduleConfiguration(setDoctorSchendule, setLoading)

    const handleSize = () => {
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  const interval =
    selectedOccupation.slug === 'clinico-geral'
      ? 60
      : selectedOccupation?.attendance_time || 30

  const handleOccupationSelecion = (e: any) => {
    const ocupation = occupations?.filter(
      (item: any) => item.id === e.target.value
    )[0]
    if (ocupation) {
      setSelectedOccupation(ocupation)
    }
    const intervalClick =
      ocupation.slug === 'clinico-geral' ? 60 : ocupation?.attendance_time || 30

    handleDateClick(
      { dateStr: newSchedule.start },
      setNewSchedule,
      intervalClick
    )
  }

  const handleCountrySelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target

    if (selectedCountry.includes(id)) {
      setSelectedCountry(selectedCountry.filter((pais: any) => pais !== id))
    } else {
      setSelectedCountry([...selectedCountry, id])
    }
  }

  return (
    <Global.Container>
      {loading && <Loading title={translate('schedule.loading')} />}
      <Header />
      <S.ContentConfig height={screenHeight}>
        <Card>
          <h1 className="title-config">
            {translate('schedule.configSchedule')}
          </h1>
          <div className="container-config">
            <div className="calendar-view">
              <FullCalendar
                ref={calendarRef}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView={screenWidth > 768 ? 'timeGridWeek' : 'timeGridDay'}
                height="100%"
                contentHeight="100%"
                locale={locales === 'en' ? enLocale : ptLocale}
                slotDuration="00:15:00"
                slotLabelInterval="00:15:00"
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  omitZeroMinute: false,
                  meridiem: 'short',
                }}
                slotMinTime="08:00:00"
                slotMaxTime="24:00:00"
                dayHeaderFormat={{
                  weekday: 'long',
                  month: 'numeric',
                  day: 'numeric',
                }}
                timeZone="UTC"
                events={[...doctorSchendule, newSchedule]}
                allDaySlot={false}
                titleFormat={
                  screenWidth > 768
                    ? { day: 'numeric', month: 'long' }
                    : { day: 'numeric', month: 'numeric' }
                }
                eventOverlap
                eventResizableFromStart
                droppable={false}
                dateClick={(e) => handleDateClick(e, setNewSchedule, interval)}
                eventContent={(info: any) => {
                  return info.event && showModal ? (
                    <OverlayConfig
                      info={info}
                      occupations={occupations}
                      selectedOccupation={selectedOccupation}
                      setSelectedOccupation={setSelectedOccupation}
                      country={country}
                      groups={groups}
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                      handleOccupationSelection={handleOccupationSelecion}
                      handleCountrySelection={handleCountrySelection}
                      newSchedule={newSchedule}
                      setNewSchedule={setNewSchedule}
                      doctorSchedule={doctorSchendule}
                      setDoctorSchedule={setDoctorSchendule}
                      setLoading={setLoading}
                    />
                  ) : (
                    <S.EventContent>
                      <p>
                        {info.event.extendedProps.status === 'confirmed'
                          ? info.event.extendedProps.occupation.name
                          : translate('schedule.notTitle')}
                      </p>
                    </S.EventContent>
                  )
                }}
              />
            </div>
          </div>
        </Card>
      </S.ContentConfig>
      <Footer />
    </Global.Container>
  )
}

export default ScheduleConfiguration
