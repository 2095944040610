import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import enLocale from '@fullcalendar/core/locales/en-nz'
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular'
import useFetch from 'hooks/useFetch'
import moment from 'moment'
import 'moment/locale/pt-br'
import { orderBy } from 'lodash'
import { translate } from 'locales/index'

// Context
import { useUser } from 'context/user/user.context'

// Styles
import * as Global from 'global/styles.global'
import { Header, Footer, Card, Button, Loading } from 'components'
import spinnerIcon from 'assets/icons/spinner.svg'
import clockIcon from 'assets/icons/clock.svg'
import checkCircleIcon from 'assets/icons/check-circle.svg'
import canceledIcon from 'assets/icons/canceled.svg'
import * as S from './styles'

// Components
import { IMetrics } from './Interfaces'

// Services
import { schedulingListFormated, findMetrics, findUserData } from './services'
import { psicoName } from './utils'

const Home = () => {
  const history = useHistory()
  const { setUserData } = useUser()

  // State
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [period, setPeriod] = useState<{ month: number; year: number }>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [metrics, setMetrics] = useState<IMetrics>({
    pending: 0,
    inProgress: 0,
    finished: 0,
    canceled: 0,
    noShow: 0,
    finishedTwo: 0,
    noShowTwo: 0,
  })

  const terms: any = window.localStorage.getItem('@StarBem:terms')
  const parsedTerms = JSON.parse(terms)

  const locales = window.localStorage.getItem('@StarBem:lang')
  const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
  const dataParse = JSON.parse(dataInStorage)

  const [days, setDays] = useState({
    start: moment().startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('week').format('YYYY-MM-DD'),
  })

  const today = moment().format('YYYY-MM-DD')
  const { data, isLoading } = useFetch({
    url: `schedulings/ms/v1/doctor/${dataParse.id}/all?start=${today}&end=${today}`,
    options: {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  const { data: schedulings, isLoading: schedulingsLoading } = useFetch({
    url: `schedulings/ms/v1/doctor/${dataParse.id}/all?start=${days.start}&end=${days.end}`,
    options: {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  const findAll = async () => {
    await findUserData(setUserData)
  }

  useEffect(() => {
    findAll()

    const handleSize = () => {
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  const handleAppointmentSelection = (
    appointmentId: string,
    areaName: string
  ) => {
    if (psicoName.includes(areaName)) {
      const area = String(areaName).normalize('NFD').toLowerCase()

      history.push(`/${area}/novo-prontuario/${appointmentId}`)
    } else {
      history.push(`/novo-prontuario/${appointmentId}`)
    }
  }

  const handleDatesSet = (info: any) => {
    const start = moment(info.start).format('YYYY-MM-DD')
    const end = moment(info.end).format('YYYY-MM-DD')
    setDays({
      start,
      end,
    })
  }

  const addPeriod = () => {
    if (period.month < 12) {
      setPeriod({
        ...period,
        month: period.month + 1,
      })
    } else if (period.month === 12) {
      setPeriod({
        ...period,
        month: 1,
        year: period.year + 1,
      })
    }
  }

  const subPeriod = () => {
    if (period.month > 1) {
      setPeriod({
        ...period,
        month: period.month - 1,
      })
    } else if (period.month === 1) {
      setPeriod({
        ...period,
        month: 12,
        year: period.year - 1,
      })
    }
  }

  useEffect(() => {
    findMetrics(setMetrics, period.month, period.year)
  }, [period])

  return (
    <Global.Container>
      {(isLoading || schedulingsLoading) && (
        <Loading title={translate('home.loading')} />
      )}
      <Header />
      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1400, margin: '0 auto' }}
      >
        <Grid item xs={12} md={3}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ padding: '2px 6px', marginBottom: 5 }}
          >
            <Card>
              <S.PatientsListToday>
                <span>{translate('home.patientToday')}</span>
                <div className="list">
                  {schedulingListFormated(data).todayPatientsList.length ===
                  0 ? (
                    <div className="list-card">
                      <span>{translate('home.notScheduleToday')}</span>
                    </div>
                  ) : (
                    orderBy(
                      schedulingListFormated(data).todayPatientsList,
                      ['date'],
                      ['asc']
                    ).map((item: any) => (
                      <button
                        type="button"
                        className="list-card"
                        key={item?.uuid}
                        onClick={() =>
                          handleAppointmentSelection(item?.uuid, item?.slug)
                        }
                        tabIndex={0}
                      >
                        <span>{item?.name}</span>

                        <span className="patient-item-time">
                          {moment.utc(item?.date).format('HH:mm')}
                        </span>
                      </button>
                    ))
                  )}
                </div>
              </S.PatientsListToday>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: '2px 6px' }}>
            <Card>
              <S.PatientsListToday>
                <S.DashboardTop>
                  <span>{translate('home.dash')}</span>
                  <div className="area-month">
                    <button type="button" onClick={subPeriod}>
                      <ChevronLeft size={30} color="#FF3F72" />
                    </button>
                    <span className="month">
                      {moment(new Date(period.year, period.month - 1))
                        .locale('pt-br')
                        .format('MMM/YYYY')}
                    </span>
                    <button
                      onClick={addPeriod}
                      type="button"
                      disabled={
                        period.month === new Date().getMonth() + 1 &&
                        period.year === new Date().getFullYear()
                      }
                    >
                      <ChevronRight size={30} color="#FF3F72" />
                    </button>
                  </div>
                </S.DashboardTop>
                <S.DashboardList>
                  <S.DashboardCard>
                    <div className="left">
                      <span>{translate('home.inAttendance')}</span>
                      {metrics.inProgress}
                    </div>
                    <div className="icon">
                      <img
                        src={spinnerIcon}
                        alt="Icone que simboliza os médicos ativos"
                      />
                    </div>
                  </S.DashboardCard>
                  <S.DashboardCard>
                    <div className="left">
                      <span>{translate('home.nextAttendance')}</span>
                      {metrics.pending}
                    </div>
                    <div className="icon">
                      <img
                        src={clockIcon}
                        alt="Icone que simboliza os médicos ativos"
                      />
                    </div>
                  </S.DashboardCard>
                  <S.DashboardCard>
                    <div className="left">
                      <span>{translate('home.finished')}</span>
                      {metrics.finished}
                    </div>
                    <div className="icon">
                      <img
                        src={checkCircleIcon}
                        alt="Icone que simboliza os médicos ativos"
                      />
                    </div>
                  </S.DashboardCard>
                  <S.DashboardCard>
                    <div className="left">
                      <span>{translate('home.canceled')}</span>
                      {metrics.canceled}
                    </div>
                    <div className="icon">
                      <img
                        src={canceledIcon}
                        alt="Icone que simboliza os médicos ativos"
                      />
                    </div>
                  </S.DashboardCard>
                  <S.DashboardCard>
                    <div className="left">
                      <span>{translate('home.noShow')}</span>
                      {metrics.noShow}
                    </div>
                    <div className="icon">
                      <img
                        src={canceledIcon}
                        alt="Icone que simboliza os médicos ativos"
                      />
                    </div>
                  </S.DashboardCard>
                  {metrics.finishedTwo > 0 && (
                    <S.DashboardCard>
                      <div className="left">
                        <span>{`${translate('home.finished')} (60 min)`}</span>
                        {metrics.finishedTwo}
                      </div>
                      <div className="icon">
                        <img
                          src={checkCircleIcon}
                          alt="Icone que simboliza os médicos ativos"
                        />
                      </div>
                    </S.DashboardCard>
                  )}
                  {metrics.noShowTwo > 0 && (
                    <S.DashboardCard>
                      <div className="left">
                        <span>{`${translate('home.noShow')} (60 min)`}</span>
                        {metrics.noShowTwo}
                      </div>
                      <div className="icon">
                        <img
                          src={canceledIcon}
                          alt="Icone que simboliza os médicos ativos"
                        />
                      </div>
                    </S.DashboardCard>
                  )}
                </S.DashboardList>
              </S.PatientsListToday>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} style={{ padding: '2px 6px' }}>
          <Card>
            <S.CalendarView height={screenHeight}>
              <S.TopContent>
                <span className="schedule-title">
                  {translate('home.mySchedule')}
                </span>
                <Button
                  type="button"
                  color="primary"
                  disabled={!parsedTerms}
                  onClick={() => history.push('/configurar-agenda')}
                >
                  {translate('home.configSchedule')}
                </Button>
              </S.TopContent>
              <FullCalendar
                plugins={[timeGridPlugin]}
                initialView={
                  screenWidth >= 768 ? 'timeGridWeek' : 'timeGridDay'
                }
                contentHeight="100%"
                height="100%"
                locale={locales === 'en' ? enLocale : ptLocale}
                slotDuration="00:15:00"
                slotLabelInterval="00:15:00"
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  omitZeroMinute: false,
                  meridiem: 'short',
                }}
                slotMinTime="08:00:00"
                slotMaxTime="24:00:00"
                dayHeaderFormat={{
                  weekday: 'short',
                  month: 'numeric',
                  day: 'numeric',
                }}
                timeZone="America/Sao_Paulo"
                firstDay={1}
                titleFormat={{ day: 'numeric', month: 'numeric' }}
                events={schedulingListFormated(schedulings).schedulingList}
                eventClick={(info) => {
                  handleAppointmentSelection(
                    info.event.id,
                    info.event.extendedProps?.slug
                  )
                }}
                allDaySlot={false}
                datesSet={handleDatesSet}
              />
            </S.CalendarView>
          </Card>
        </Grid>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}

export default Home
