import React from 'react'
import api from 'configs/api'
import axios from 'axios'
import { alertDanger, alertSuccess } from 'utils/toast'
import { translate } from 'locales'
import prescriptionData from './prescriptionData'
import { IState, IMedicalRecord } from './interfaces'

export const findSchedule = async (
  uuid: string,
  setSchedule: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const find = await api.get(`schedulings/v1/${uuid}/doctor`)
    setSchedule(find.data)
    setLoading(false)
  } catch (err) {
    console.error(err)
    setLoading(false)
  }
}

export const findVitalsSigns = async (
  uuid: string,
  setVitalsSigns: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const find = await api.get(
      `/accounts/v1/health-check/user/${uuid}/last?take=1`
    )

    setVitalsSigns(find.data.healthChecks[0])
    setLoading(false)
  } catch (err) {
    console.error(err)
    setLoading(false)
  }
}

export const findAllUserMedicalRecord = async (
  patient: any,
  isActive: boolean,
  setTimeline: React.Dispatch<React.SetStateAction<Array<any>>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  page: number,
  setTotalPages: any
) => {
  setLoading(true)
  try {
    const find = await api.get(
      `/doctors/v2/medical-record/user/${patient.id}?page=${page}&limit=5`
    )

    setTotalPages(find.data?.totalPages)
    setTimeline(find.data?.medicalRecords)
    setLoading(false)
  } catch (err: any) {
    alertDanger(
      err?.response?.data?.data?.errors[0].msg ||
        translate('errors.internalError')
    )
    setLoading(false)
  }
}

export const savePrescription = async (
  data: any,
  scheduleUUID: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const schedule: any = window.localStorage.getItem(
    `starbem:scheduling:${scheduleUUID}`
  )
  const scheduleData = JSON.parse(schedule)

  try {
    await api.post('/doctors/v1/prescription', {
      user_id: scheduleData.patient.id,
      schedule_id: scheduleData.id,
      patient_url: data.UrlPacientes,
      validation_code: data.CodigoValidacao,
      documents: data.Documentos,
    })
  } catch (err) {
    console.error(err)
  }
  setLoading(false)
}

export const handlePrescription = async ({
  appointment,
  medical_record,
  user_data,
  state,
  setLoading,
  setState,
}: {
  appointment: any
  medical_record: any
  user_data: any
  state: IState
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setState: React.Dispatch<React.SetStateAction<IState>>
}) => {
  const userStorage: any = window.localStorage.getItem('@StarBem:user')
  const doctor_data = JSON.parse(userStorage)

  const payload = prescriptionData

  const specialties: string[] = [appointment.specialty_name]

  const height = String(medical_record?.height).replace(/[^\d]+/g, '')

  payload.Medico.Especialidades = specialties || ['Clínica Geral']
  payload.Medico.Documento = doctor_data.profile?.document
  payload.Medico.RegistroProfissional.Numero =
    doctor_data.profile.professional_document_number
  payload.Medico.RegistroProfissional.UF =
    doctor_data.profile.professional_document_uf
  payload.Medico.RegistroProfissional.Conselho =
    doctor_data.profile.professional_document_type
  payload.Medico.Email = doctor_data.email
  payload.Medico.Nome = doctor_data.name

  payload.Paciente.Nome = user_data?.name
  payload.Paciente.Documento = user_data?.document
  payload.Paciente.TelefoneCelular = user_data?.cellphone
  payload.Paciente.Nascimento = user_data?.birthdate
  payload.Paciente.Email = user_data?.email
  payload.Paciente.Sexo = ['other', 'female'].includes(user_data?.gender)
    ? 'feminino'
    : 'masculino'
  payload.Paciente.Altura = String(height)
  payload.Paciente.Peso = medical_record?.weight
  payload.RegistroProntuarioEletronico.ReferenciaExterna = appointment?.id
  payload.Paciente.Endereco.Endereco1 =
    `${user_data?.street}, ${user_data?.number}` || ''
  payload.Paciente.Endereco.Endereco2 = user_data?.complement || ''
  payload.Paciente.Endereco.Bairro = user_data?.neighborhood || ''
  payload.Paciente.Endereco.Cidade = user_data?.city || ''
  payload.Paciente.Endereco.Estado = user_data?.state || ''
  payload.Paciente.Endereco.CodigoPostal = user_data?.zipcode || ''

  setLoading(true)
  setState({ ...state, message: translate('loadings.createPrescription') })
  console.log(payload)

  try {
    const createPrescription = await axios.post(
      `${process.env.REACT_APP_NEXODATA_API_URL}/prescricao/iniciar`,
      payload,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_NEXODATA_API_KEY}`,
        },
      }
    )

    const modalURL = createPrescription.data.ModalURL

    setState({
      ...state,
      url: modalURL,
      showModal: true,
    })
    setLoading(false)
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err.response.data[0].Descricao || translate('errors.errorPrescription')
    )
  }
}

export const InitSchedule = async ({
  scheduleUUID,
  data,
  setLoading,
  // history,
  action,
}: {
  scheduleUUID: string
  data: {
    status: string
    cancel_description?: string
  }
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  history: any
  action?: any
}) => {
  setLoading(true)
  try {
    await api.patch(`/schedulings/ms/v1/${scheduleUUID}/doctor/status`, data)
    if (action) {
      action()
    }
    setLoading(false)
    alertSuccess(translate('success.schedulingStarted'))
  } catch (err) {
    console.error(err)
    setLoading(false)
    alertDanger(translate('errors.errorTry'))
  }
}

export const CancelSchedule = async ({
  scheduleUUID,
  data,
  setLoading,
  history,
  action,
}: {
  scheduleUUID: string
  data: {
    cancel_description?: string
  }
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  history: any
  action?: any
}) => {
  setLoading(true)

  try {
    await api.patch(`/schedulings/ms/v1/${scheduleUUID}/doctor/cancel`, data)
    if (action) {
      action()
    }
    history.replace('/dashboard')
    window.localStorage.removeItem(`clickTime${scheduleUUID}`)
    setLoading(false)
    alertSuccess(translate('success.schedulingCanceled'))
  } catch (err) {
    console.error(err)
    setLoading(false)
    alertDanger(translate('errors.errorTry'))
  }
}

export const initVideoCall = async (
  schedulingId: string,
  state: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setState: React.Dispatch<React.SetStateAction<any>>,
  setToken: React.Dispatch<React.SetStateAction<any>>
) => {
  setState({ ...state, message: translate('loadings.createRoom') })
  setLoading(true)

  try {
    const { data } = await api.get(
      `/video/v3/room/appointment/${schedulingId}/create/token`
    )

    setToken(data.token)
    localStorage.setItem('callToken', data.token)
    localStorage.setItem('callAppointmentId', schedulingId)
    setState({ ...state, message: '' })
  } catch (error) {
    alertDanger(translate('errors.errorTry'))
    console.log(error)
  }

  setLoading(false)
}

export const saveMedicalRecord = async (
  userUUID: string,
  scheduleUUID: string,
  data: IMedicalRecord,
  history: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!(!!data.complaint && !!data.history && !!data.conduct)) {
    return alertDanger(translate('errors.fieldsRequired'))
  }
  setLoading(true)

  try {
    const params = {
      user_uuid: userUUID,
      schedule_uuid: scheduleUUID,
      complaint: data.complaint,
      history: data.history,
      allergies: data.allergies,
      diabetes: data.diabetes,
      pathological_background: data.pathologicalBackground,
      continuous_remedy: data.continuousRemedy,
      exams: data.exams,
      hypothesis: data.hypothesis,
      clinical_impression: data.clinicalImpression,
      conduct: data.conduct,
      heart_rate: data.heartRate ? String(data.heartRate) : null,
      systolic_blood_pressure: data.systolicBloodPressure
        ? String(data.systolicBloodPressure)
        : null,
      diastolic_blood_pressure: data.diastolicBloodPressure
        ? String(data.diastolicBloodPressure)
        : null,
      respiratory_frequency: data.respiratoryFrequency
        ? String(data.respiratoryFrequency)
        : null,
      co2_saturation:
        data.irregularHeartbeat || data.irregularHeartbeat >= 0
          ? String(data.irregularHeartbeat)
          : null,
    }

    const save = await api.post('/doctors/v1/medical-record', params)

    if (save.status === 200) {
      await api.patch(`/schedulings/ms/v1/${scheduleUUID}/doctor/status`, {
        status: 'finished',
      })
      setLoading(false)
      history.replace('/dashboard')
      window.localStorage.removeItem(`starbem:schedule:${scheduleUUID}`)
      window.localStorage.removeItem(`starbem:scheduling:${scheduleUUID}`)
      window.localStorage.removeItem(`clickTime${scheduleUUID}`)
      return alertSuccess(translate('success.schedulingFinished'))
    }
    setLoading(false)
    return alertDanger(
      save.data?.data?.errors[0].msg || translate('errors.errorTry')
    )
  } catch (err: any) {
    setLoading(false)
    return alertDanger(
      err.response?.data?.data?.errors[0].msg ||
        translate('errors.internalError')
    )
  }
}

export const createAccompaniment = async (
  data: any,
  verify: IMedicalRecord
) => {
  if (!(!!verify.complaint && !!verify.history && !!verify.conduct)) {
    return alertDanger(translate('errors.fieldsRequired'))
  }

  try {
    const response = await api.post('/doctors/v1/accompaniment', data)
    return response.data
  } catch (err: any) {
    return alertDanger(
      err.response?.data?.data?.message || translate('errors.internalError')
    )
  }
}

export const sendCallPush = async (uuid: string) => {
  try {
    await api.post(`schedulings/v1/${uuid}/call`)
  } catch (err) {
    console.log(err)
  }
}
