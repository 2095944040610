import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { createFirstAndLastName } from 'utils'
import {
  Avatar,
  Menu as MaterialMenu,
  MenuItem,
  Modal,
} from '@material-ui/core'
import { translate } from 'locales'
import { alertSuccess } from '../../utils/toast'
import TermsModal from 'components/Modal/TermsModal'

import api from 'configs/api'

// Contexts
import { useSignIn } from '../../context/authentication/signin.context'
import { useUser } from '../../context/user/user.context'

import './styles.scss'

// Components
import { Menu, MenuMobile } from '../index'

// Images
import logo from '../../assets/images/logo.svg'

const Header: React.FC<{
  openNewTab?: boolean
  onCloseRoom?: () => void
  setAcepptTerm?: () => void
}> = ({ openNewTab = false }) => {
  const history = useHistory()
  const { logout } = useSignIn()
  const { userData } = useUser()

  const [showTermsModal, setShowTermsModal] = useState(false)
  const [term, setTerm] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showModal, setShowModal] = React.useState(false)
  const [clickedRoute, setClickedRoute] = React.useState('')

  const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
  const dataParse = JSON.parse(dataInStorage)

  const router = window.location.pathname

  const handleTermsStatus = async () => {
    try {
      const response = await api.get('/doctors/ms/v1/me')
      const { terms_authorization } = response.data
      setTerm(terms_authorization)
      window.localStorage.setItem(
        '@StarBem:terms',
        JSON.stringify(terms_authorization)
      )
      if (terms_authorization === true) {
        setShowTermsModal(false)
      } else if (router === '/dashboard') {
        setShowTermsModal(true)
      }
    } catch (error) {
      console.error('Erro ao verificar o status dos termos:', error)
    }
  }

  const handleAcceptTerms = () => {
    setShowTermsModal(false)
    handleTermsStatus()
    window.location.reload()
  }

  const handleDenyingTerms = () => {
    setShowTermsModal(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    history.replace('/')
    alertSuccess(translate('header.disconnectSuccess'))
  }

  const handleRedirect = (route: string) => {
    history.push(route)
  }

  const handleClickMenu = (route: string) => {
    setClickedRoute(route)

    if (openNewTab) {
      setShowModal(true)
    } else {
      handleRedirect(route)
    }
  }

  const leaveVideoCall = (route: string) => {
    const videoCallEndButton = document.getElementById('video_call_end')
    videoCallEndButton?.click()

    setShowModal(false)
    handleRedirect(route)
  }

  useEffect(() => {
    handleTermsStatus()
  }, [])

  return (
    <header id="header">
      <button
        type="button"
        className="header-logo transparent-button"
        onClick={() => handleClickMenu('/dashboard')}
      >
        <img src={logo} alt="Starbem" />
      </button>
      <Menu
        openNewTab={openNewTab}
        setShowModal={setShowModal}
        setClickedRoute={setClickedRoute}
        redirect={handleRedirect}
      />
      <MenuMobile
        openNewTab={openNewTab}
        actionNewTab={handleClickMenu}
        setShowTermsModal={() => setShowTermsModal(true)}
      />
      <div className="header-user">
        <Avatar src={userData.profile.photo} />
        <button
          type="button"
          className="transparent-button"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <span>{createFirstAndLastName(userData.name)}</span>
        </button>
        <MaterialMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push('/perfil')}>
            {translate('header.myProfile')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowTermsModal(true)
              handleClose()
            }}
          >
            {' '}
            {translate('header.terms')}
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>
            {translate('header.disconnect')}
          </MenuItem>
        </MaterialMenu>
      </div>
      {leaveVideoCall && (
        //@ts-ignore
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={showModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-alert-container">
            <div className="modal-alert-description">
              Você está com uma vídeo chamada em andamento, se sair agora você
              será desconectado e o paciente não conseguirá te ver nem ouvir!
            </div>
            <div className="modal-alert-description modal-alert-confirmation">
              Deseja realmente sair?
            </div>
            <div className="modal-alert-buttons">
              <button
                className="modal-alert-btn b-yes"
                onClick={() => leaveVideoCall(clickedRoute)}
              >
                Sim
              </button>
              <button
                className="modal-alert-btn b-no"
                onClick={() => setShowModal(false)}
              >
                Não
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showTermsModal && (
        <TermsModal
          userId={dataParse.id}
          onAccept={handleAcceptTerms}
          term={term}
          inDenying={handleDenyingTerms}
        />
      )}
    </header>
  )
}

export default Header
