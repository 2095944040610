import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'components'
import * as S from './styles'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'
import { translate } from 'locales/index'

interface TermsModalProps {
  userId: string
  term?: boolean
  onAccept: () => void
  inDenying: () => void
}

const TermsModal: React.FC<TermsModalProps> = ({
  userId,
  onAccept,
  term,
  inDenying,
}) => {
  const [termsContent, setTermsContent] = useState('')
  const [canAccept, setCanAccept] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const termsRef = useRef<HTMLDivElement>(null)
  const locales = window.localStorage.getItem('@StarBem:lang')

  const fetchTerms = async () => {
    setLoading(true)
    try {
      const response = await api.get('backoffice/ms/v1/terms?type=doctor', {
        headers: {
          'Accept-Language': locales || 'pt',
        },
      })
      const termsData =
        response.data[0]?.terms || '<p>Termos indisponíveis.</p>'

      setTermsContent(termsData)
      setError('')
    } catch (error) {
      console.error('Erro ao buscar os termos:', error)
      setError('Erro ao carregar os termos. Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const handleAcceptTerms = async () => {
    try {
      const response = await api.patch(`doctors/ms/v1/user/${userId}/terms`)
      alertSuccess(response.data.message)
      onAccept()
      inDenying()
    } catch (error) {
      console.error('Erro ao aceitar os termos:', error)
      alertDanger('Erro ao aceitar os termos. Tente novamente.')
    }
  }

  const handleScroll = () => {
    if (!termsRef.current) return
    const { scrollTop, scrollHeight, clientHeight } = termsRef.current
    if (scrollHeight <= clientHeight) {
      setCanAccept(true)
    } else {
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setCanAccept(true)
      }
    }
  }

  useEffect(() => {
    fetchTerms()
  }, [])

  return (
    <>
      <S.Overlay />
      <S.TermsModal>
        {loading ? (
          <p>Carregando termos...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          <div
            ref={termsRef}
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '10px',
              marginBottom: '20px',
            }}
            onScroll={handleScroll}
          >
            <div dangerouslySetInnerHTML={{ __html: termsContent }} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <Button
            color="default"
            type="button"
            onClick={inDenying}
            styles={{ width: !term ? '48%' : '100%' }}
          >
            {translate('home.termButton')}
          </Button>
          {!term && (
            <Button
              color="primary"
              type="button"
              onClick={handleAcceptTerms}
              disabled={!canAccept || loading || !!error}
              styles={{ width: '48%' }}
            >
              {translate('home.termButtonAccept')}
            </Button>
          )}
        </div>
      </S.TermsModal>
    </>
  )
}

export default TermsModal
