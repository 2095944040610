const menuItems = [
  {
    id: 1,
    name: 'Agenda',
    route: '/dashboard',
  },
  {
    id: 2,
    name: 'Pacientes Atendidos',
    route: '/pacientes',
  },
  {
    id: 3,
    name: 'Atendimentos Avaliados',
    route: '/avaliados',
  },
]

export default menuItems
